import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';
import Theme from 'components/template/Theme';
import Layout from 'components/layout';
import './locales';
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  ApolloProvider,
  concat,
  split,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { onError } from '@apollo/client/link/error';
import { onSignInSuccess } from 'store/auth/sessionSlice';
import { useDispatch } from 'react-redux';
import { firebaseConfig } from 'services/firebase';
import StoreProvider from 'context/Store';
import AuthProvider from 'context/Auth';
import SubscriptionProvider from 'context/Subscription';
import { useSelector } from 'react-redux';


function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ClientContainer>
          <BrowserRouter>
            <AuthProvider>
              <SubscriptionProvider>
                <StoreProvider>
                  <Theme>
                    <Layout />
                  </Theme>
                </StoreProvider>
              </SubscriptionProvider>
            </AuthProvider>
          </BrowserRouter>
        </ClientContainer>
      </PersistGate>
    </Provider>
  );
}

const refetchRefreshToken = async (dispatch) => {
  const refreshToken = localStorage.getItem('refresh_token');
  if (firebaseConfig?.apiKey && refreshToken) {
    const url = `https://securetoken.googleapis.com/v1/token?key=${firebaseConfig?.apiKey}`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `grant_type=refresh_token&refresh_token=${refreshToken}`,
    });

    const data = await response.json();
    const token = data?.access_token;

    dispatch(onSignInSuccess(token));
    localStorage.setItem('token', token);
  }
};

export const ClientContainer = ({ children }) => {
  const dispatch = useDispatch();
  const uri = process.env.REACT_APP_HASURA_ENDPOINT;
  const { token } = useSelector((state) => state.auth.session);
  const headers = token ? { authorization: `Bearer ${token}` } : {};

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    const arr = [];
    if (networkError) arr.push(networkError);
    if (graphQLErrors) arr.push(...graphQLErrors);

    if (arr?.length > 0) {
      arr.forEach((item) => {
        const { message, locations, path } = item || {};
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      });
    }

    if (
      arr.find(
        ({ message }) =>
          message?.includes('Could not verify JWT') ||
          message?.includes('Malformed Authorization header')
      )
    ) {
      refetchRefreshToken(dispatch);
    }
  });

  const httpLink = new HttpLink({ uri });
  const wsLink = new GraphQLWsLink(
    createClient({
      url: uri?.replace('https', 'wss').replace('http', 'ws'),
      connectionParams: {
        headers,
      },
    })
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers: ctxHeaders = {} }) => {
      return {
        headers: {
          ...ctxHeaders,
          ...headers,
        },
      };
    });
    return forward(operation);
  });

  const client = new ApolloClient({
    uri,
    cache: new InMemoryCache(),
    link: ApolloLink.from([errorLink, concat(authMiddleware, splitLink)]),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default App;
